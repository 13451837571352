import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import sortBy from "lodash/sortBy"
import React, { useState } from "react"
import { BounceLoader } from "react-spinners"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Category = ({ index, data: category }) => {
  const [expanded, setExpanded] = useState(true)

  if (category.nodes.length > 0) {
    const items = category.nodes
    const categoryData = items[0].category

    return (
      <div
        key={category.id}
        className="bg-white mt-4 sm-rounded-none md:rounded shadow text-gray-800"
      >
        <div
          onClick={() => setExpanded(!expanded)}
          onKeyDown={event => event.keyCode !== 9 && setExpanded(!expanded)}
          tabIndex={index}
          role="button"
          aria-pressed="false"
          className="flex items-center justify-between bg-blue-900 sm:rounded-none md:rounded shadow cursor-pointer text-white border-b border-solid border-gray-200 mb-2s p-4"
        >
          <div className="mr-4">
            <h2 className="font-bold text-3xl tracking-wide leading-relaxed">
              {categoryData.name}
            </h2>
            <span className="text-blue-100 hidden md:inline-block">
              {categoryData.description &&
                categoryData.description.map(val =>
                  val.children.map(({ text, _key }) => <p key={_key}>{text}</p>)
                )}
            </span>
          </div>
          <div className="text-2xl">
            <FontAwesomeIcon
              icon={expanded ? faChevronCircleUp : faChevronCircleDown}
            />
          </div>
        </div>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              key="item-content"
              initial="open"
              animate="open"
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                  height: "auto",
                  transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.1,
                  },
                },
                closed: {
                  opacity: 0,
                  height: 0,
                  transition: {
                    when: "afterChildren",
                  },
                },
              }}
            >
              <AnimatePresence>
                {sortBy(items, item => item.order || 100).map(item => {
                  console.log(item)
                  return (
                    <motion.div
                      key={item.id}
                      variants={{
                        open: { opacity: 1 },
                        closed: { opacity: 0 },
                      }}
                      className="hover:bg-gray-50 p-4 border-b border-solid"
                    >
                      <div className="flex justify-between items-center">
                        {/* {item.image && (
                        <div className="w-16 h-16 mr-2">
                        <Img fixed={item.image.asset.fixed} />
                        </div>
                      )} */}
                        <div>
                          <div className="font-semibold mr-4">{item.name}</div>
                          <div
                            className="text-gray-500"
                            style={{ margin: "auto" }}
                          >
                            {item.description &&
                              item.description.map(val =>
                                val.children.map(({ text, _key }) => (
                                  <p key={_key}>{text}</p>
                                ))
                              )}
                          </div>
                        </div>
                        <div className="ml-4 text-gray-700 font-bold">
                          ${item.price.toFixed(2)}
                        </div>
                      </div>
                    </motion.div>
                  )
                })}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  }
}

const MenuPage = () => {
  const data = useStaticQuery(graphql`
    query menuPage {
      allSanityMenuItem {
        group(field: category___id) {
          fieldValue
          nodes {
            name
            id
            price
            order
            image {
              asset {
                fixed(width: 32) {
                  ...GatsbySanityImageFixed
                }
              }
            }
            category {
              name
              id
              order
              description {
                children {
                  _key
                  text
                }
              }
            }
            description {
              children {
                _key
                text
              }
            }
          }
        }
      }
    }
  `)

  const categories = data?.allSanityMenuItem?.group ?? []

  return (
    <>
      <Layout>
        <SEO title="Home" />

        <div className="flex justify-center mt-16">
          <div className="container mb-12">
            {data &&
              sortBy(
                categories,
                cat => cat.nodes[0].category.order
              ).map((category, i) => (
                <Category data={category} index={i} color="#1E40AF" />
              ))}

            {!data && <BounceLoader size={120} color="" />}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default MenuPage
